import { InputAdornment, TextField, TextFieldProps, Tooltip } from '@mui/material';
import React, { CSSProperties } from 'react';
import { useError } from '../helpers/hooks';

export type ITextFieldProps = TextFieldProps & {
  isUppercaseValue?: boolean;
  fontSize?: string | number;
  textAlign?: 'left' | 'right';
  prefix?: JSX.Element | string;
  suffix?: JSX.Element;
  tabIndex?: number;
  maxLength?: number;
  autoComplete?: string;
  tooltip?: string;
  counter?: number;
};

export const XsTextField = (props: ITextFieldProps) => {
  const {
    isUppercaseValue = false,
    prefix = null,
    suffix = null,
    fontSize,
    textAlign = 'left',
    name,
    disabled,
    maxLength,
    onChange,
    onBlur,
    tabIndex,
    autoComplete,
    tooltip,
    counter,
    ...rest
  } = props;

  const { error, helperText } = useError(name);

  let startAdornment: React.ReactNode | null = prefix;
  let endAdornment: React.ReactNode | null = suffix;

  if (typeof prefix === 'string') {
    startAdornment = <InputAdornment position='start'>{prefix}</InputAdornment>;
  }
  if (typeof prefix === 'string') {
    endAdornment = <InputAdornment position='end'>{suffix}</InputAdornment>;
  }

  const alignStyle: CSSProperties =
    textAlign === 'right'
      ? {
          textAlign: 'right',
          paddingRight: 5
        }
      : {};

  return (
    <Tooltip title={tooltip ?? ''} placement='top'>
      <TextField
        variant='outlined'
        fullWidth
        size={'small'}
        margin={'none'}
        type={'text'}
        autoComplete={autoComplete ? autoComplete : 'off'}
        inputProps={{
          tabIndex,
          maxLength,
          autoComplete: autoComplete ? autoComplete : 'off',
          style: { fontSize: fontSize, ...alignStyle }
        }}
        InputProps={{
          startAdornment: startAdornment,
          endAdornment: endAdornment
        }}
        InputLabelProps={{ shrink: true }}
        disabled={disabled}
        {...rest}
        label={counter ? `${rest.label} (${(rest.value as null | string)?.length ?? '0'}/${counter})` : rest.label}
        onChange={(e) => {
          if (isUppercaseValue) {
            e.target.value = e.target.value.toUpperCase();
          }
          if (props.onChange) props.onChange(e);
        }}
        error={error}
        onBlur={(e) => {
          if (props.onBlur) {
            props.onBlur(e);
          }
        }}
        helperText={helperText}
      />
    </Tooltip>
  );
};
